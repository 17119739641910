import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import PageHeader from "../components/page-header"
import Reasons from "../components/reasons"
import Statistics from "../components/statistics"
import Formulas from "../components/formulas"


import Img from "gatsby-image"


class EventPage extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    const {data} = this.props;
    const eventPosts = data.allContentfulEvent.edges;
    eventPosts.sort((a,b) => new Date(a.node.date) - new Date(b.node.date));

    const path = this.props.location ? this.props.location.pathname : null;

    return (
      <Layout path={path}>
        <PageHeader data={"Nos évènements"} />

        <div id="events">
          {eventPosts.length > 0 ? (
            <div id="event-posts">
              {eventPosts.map((object, i) => (
                <EventPost data={object.node} key={i}/>
              ))}
            </div>
          ) : (
            <div id="no-event-post">
              <h5> Aucun évènement ou formation </h5>
            </div>
          )}
        </div>
        <Reasons />
        <Statistics />
        <Formulas />
      </Layout>
    )
  }

}


const EventPost = ({ data }) => {

  const date = new Date(data.date)

  return (
    <div id="event-post">
      <Link to={(data.formation ? "/formations/" : "/events/") + data.slug}>
        <Img className="img-thumbnail" fluid={data.image.fluid} imgStyle={{ objectFit: "cover" }}/>
      </Link>
      <div className="content-thumnail">
        <div className="first-row">
          <span>@{data.place && data.place.toUpperCase()}</span>
          <span className="date-post">Prochaine date : {date.toLocaleDateString()}</span>
        </div>
        <div className="middle-row">
          <Link to={(data.formation ? "/formations/" : "/events/") + data.slug} className="title-post">{data.title}</Link>
          <Link to={(data.formation ? "/formations/" : "/events/") + data.slug} className="excerpt-post">{data.excerpt}</Link>
        </div>
        <div className="last-row">
          <div className="presenter-container">
            <div className="profile-picture">
              <Img className="profile-picture--content" fluid={data.presenter.photo.fluid} imgStyle={{ objectFit: "cover" }}/>
            </div>
            <span className="presenter-name">{data.presenter.nom}</span>
          </div>
          <div className="read-more-container">
            <Link to={(data.formation ? "/formations/" : "/events/") + data.slug}>Lire la suite</Link>
            <div className="link-arrow">
              <div className="link-arrow__head"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default EventPage

export const eventQuery = graphql`
  query eventQuery {
    allContentfulEvent {
      edges {
        node {
          date
          excerpt
          formation
          place
          title
          slug
          image {
            fluid(quality: 100) {
              src
            }
          }
          presenter {
            nom
            photo {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  }
`
